import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Alert,Container } from 'react-bootstrap'
import { pw } from '../params'

export default class Halo extends Component {
    render() {
        return (
            <>
            <Container className="col-md-6 py-5 py-md-0" style={{height:'100vh',backgroundColor:'white'}}>
                <img src={pw("asset","404.png")} className="w-100 img-fluid"/>
            </Container>

            </>
        )
    }
}